<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "Blanklayout",

  components: {},
};
</script>